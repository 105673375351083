/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~swiper/swiper-bundle.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

/* Variables */
$color-dark-success: #1f6a08;

.link {
    color: black;
    text-decoration: underline;

    &.block {
        display: block;
    }

    &.inline-block {
        display: inline-block;
    }
}

ion-button {
    --border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: capitalize;

    &.classic-width {
        min-width: 140px;
        min-height: 40px;
    }

    &.no-borders::part(native) {
        border-color: transparent;
    }

    &[fill='outline'] {
        &.on-primary-btn {
            --background: var(--ion-color-on-primary);
            --color: var(--ion-color-primary);
            --border-color: var(--ion-color-on-primary);
        }

        &.on-primary-btn-outlined {
            --background: var(--ion-color-primary);
            --color: var(--ion-color-on-primary);
            --border-color: var(--ion-color-on-primary);
        }
    }

    &.outline {
        --background: var(--ion-color-on-primary);
        --background-activated: var(--ion-color-on-primary);
        --background-focused: var(--ion-color-on-primary);
        --color: var(--ion-color-primary);
        --color-activated: var(--ion-color-primary);
        --color-focused: var(--ion-color-primary);
        --background-hover: var(--ion-color-primary);
        --color-hover: var(--ion-color-on-primary);
        --background-hover-opacity: 1;
        --background-focused-opacity: 1;
        --background-activated-opacity: 1;
        border: 1px solid;
        border-radius: 4px;
    }

    &.icon-only {
        --border-radius: 50%;
        --border-width: 3px;
        --padding-start: 5px !important;
        --padding-end: 5px !important;
        width: 40px;
        height: 40px;
        min-height: 40px;

        button {
            border: none;
        }

        ion-icon {
            min-width: 40px;
            height: 40px;
            font-weight: bold;
        }
    }
}

body {
    --ion-font-family: Montserrat, 'Helvetica Neue', sans-serif;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
}

.ion-color-dark-success {
    color: $color-dark-success;
}

.cursor-pointer {
    cursor: pointer;
}

.errors-wrapper {
    ion-text.error {
        display: inline-block;
        font-size: 12px;
    }
}

/*PDF styles*/
.pf {
    margin: 0 auto !important;
}

.clearfix::after {
    display: table;
    clear: both;
    content: '';
}

ion-modal::part(content) {
    --max-width: 80vw !important;
    --max-height: 400px !important;
}

ion-modal.allow-zoom::part(backdrop) {
    touch-action: pinch-zoom;
}

.tags-modal {
    --border-radius: 0;
    --height: 420px;
    --width: 300px;
}

.custom-promotion-modal {
    --height: 370px;
    --width: 325px;
}

.price-modifiers-modal {
    --height: 450px;

    &::part(content) {
        max-height: 600px;
    }
}

.monthly-investment-modal {
    --height: 270px;
    --width: 400px;
}

.list-items-modal {
    --height: 400px;
    --width: 400px;
}

.list-items-modal,
.list-promotion-items-modal {
    --height: 400px;
    --width: 400px;
}

.promotions-summary-modal {
    --height: 290px;
    --width: 480px;
}

.pricing-details-modal {
    --height: 350px;
    --width: 480px;
}

.request-approval-modal::part(content) {
    --height: 500px;
    --max-height: 500px !important;
    --width: 400px;
}

.select-devices-modal {
    --height: 450px;

    &::part(content) {
        max-height: 600px;
    }
}

.status-bar-modal {
    --height: 300px;
    --width: 500px;
    --border-radius: 0;
}

.request-application-modal {
    --height: 600px;

    &::part(content) {
        max-height: 470px;
    }
}

.loading-modal {
    --height: 215px;

    &::part(content) {
        max-width: 410px;
        max-height: 215px;
    }
}

.offline-alert {
    --max-width: 350px !important;
}

.quick-invoice-modal::part(content) {
    width: 550px;
    height: 80vh;
    max-height: 630px;
}

.signature-modal {
    --height: 445px;
    --width: 530px;

    &::part(content) {
        max-height: 445px;
    }
}

.modal-l {
    --height: 600px;
    --width: 730px;

    &::part(content) {
        max-height: 600px;
    }
}

.feedback-modal::part(content) {
    --width: 600px;
    --height: 440px;
    --max-height: 440px !important;
}

.terms-modal::part(content) {
    width: 80vw;
    max-width: 900px;
    min-height: 90vh;
}

.change-custom-tax-modal::part(content) {
    width: 400px;
    height: 245px;
}

.available-update-modal::part(content) {
    --width: 355px;
    --height: 170px;
}

.leave-modal::part(content) {
    --width: 355px;
    --height: 220px;
}

.copy-package-modal {
    --height: 450px;
    --width: 600px;
}

.create-appointment-modal {
    --width: 600px;
    --height: 500px;

    &::part(content) {
        max-height: 440px;
    }

    .modal-body__form {
        max-height: 100%;
    }
}

.create-appointment-v2-modal {
    --width: 600px;
    --height: 600px;

    &::part(content) {
        max-height: 540px;
    }

    .modal-body__form {
        max-height: 100%;
    }
}

.create-adder-modal::part(content) {
    --width: 500px;
    --height: 580px;
    max-height: 580px;
}

.adders-modal::part(content) {
    --width: 900px;
    --height: 600px;
    max-height: 700px;
}

.roofing-dimensions-modal::part(content) {
    --width: 600px;
    --height: 570px;
    max-height: 570px;
}

.past-appointment-modal,
.result-appointment-modal {
    &::part(content) {
        --width: 700px;
        --height: 580px;
        max-height: 580px;
    }

    .result-appointment-confirm-text {
        font-size: 18px;
    }
}

.quote-update-pk-result-modal {
    &::part(content) {
        --width: 800px;
        --height: 580px;
        max-height: 580px;
    }
}

.email-resend-modal::part(content) {
    --height: 540px;
    max-height: 540px;
}

.select-client-modal::part(content) {
    --width: 80vw !important;
    --height: 600px !important;
    --max-height: 600px !important;
}

.series-changes-modal::part(content) {
    --height: 600px !important;
    --max-height: 600px !important;
}

.select-pricing-profile-modal {
    &::part(content) {
        --width: 800px;
        --height: 420px;
        max-height: 420px;
    }

    &.select-pricing-profile-smaller-modal::part(content) {
        --height: 322px;
        max-height: 322px;
    }
}

.select-price-book-modal::part(content) {
    --width: 800px;
    --height: 322px;
    max-height: 322px;
}

.preference-type-modal::part(content) {
    --width: 500px;
    --height: 350px;
    max-height: 500px;
}

.import-provia-order-modal::part(content) {
    --width: 500px;
    --height: 500px;
    max-height: 500px !important;
}

.login-modal::part(content) {
    --width: 400px;
    --height: 435px;
    --max-height: 435px !important;
}

.upload-photos-modal::part(content) {
    --width: 500px;
    --height: 580px;
    --max-height: 580px !important;
}

.upload-modal::part(content) {
    --width: 1000px;
    --height: 480px;
    --max-height: 580px !important;
}

.upload-photos-with-tabs-modal::part(content) {
    --width: 550px;
    --height: 550px;
    --max-height: 550px !important;
}

.upload-photos-modal::part(content) {
    --width: 550px;
    --height: 450px;
    --max-height: 450px !important;
}

.user-preferences-modal::part(content) {
    --height: 550px;
    --max-height: 550px !important;
}

.measurement-reports-modal {
    &::part(content) {
        --width: 550px;
        --height: 577px;
        max-height: 577px;
    }

    &.with-logged-as::part(content) {
        --height: 610px;
        max-height: 610px;
    }
}

.measurement-report-details-modal::part(content) {
    --width: 550px;
    --height: 675px;
    max-height: 675px;
}

.keyboard-shown {
    .project-details-modal::part(content) {
        --height: 360px !important;
        --max-height: 360px !important;
    }

    .measurement-reports-modal {
        --height: 500px;

        &::part(content) {
            max-height: 360px;
        }

        .modal-body {
            .order-fields {
                height: 210px !important;
            }
        }
    }

    .measurement-report-details-modal {
        --height: 500px;

        &::part(content) {
            max-height: 400px;
        }
    }

    .select-client-modal {
        &::part(content) {
            --width: 80vw !important;
            --height: 350px !important;
            --max-height: 350px !important;
        }

        .modal-body__content {
            height: 180px !important;
        }
    }

    .create-appointment-modal {
        --width: 600px;
        --height: 500px;

        &::part(content) {
            max-height: 400px;
        }

        .modal-body__form {
            max-height: 200px;
            margin: auto;
        }
    }

    .create-adder-modal,
    .set-quote-headers-modal,
    .result-appointment-modal {
        --width: 500px;
        --height: 500px;

        &::part(content) {
            max-height: 400px;
        }

        .modal-body__form {
            max-height: 250px;
            margin: auto;
        }
    }
}

@media (min-height: 763px) {
    .result-appointment-modal::part(content) {
        --width: 800px;
        --height: 650px;
        max-height: 650px;
    }

    .create-appointment-modal {
        --width: 600px;
        --height: 550px;

        &::part(content) {
            max-height: 550px;
        }
    }
}

@media (max-width: 767px) {
    ion-modal::part(content) {
        max-height: 600px;
    }
}

.full-screen {
    --width: 100%;
    --height: 100%;

    &::part(content) {
        --width: 100%;
        --height: 100vh;
        max-width: 100%;
        max-height: 100vh;
        max-height: -webkit-fill-available;
        border-radius: 0;
    }
}

.flex-center {
    display: flex;
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.flex-end {
    justify-content: flex-end;
}

ion-popover {
    &::part(content) {
        --width: var(--select-popover-width);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    }

    &::part(arrow) {
        z-index: 11;
    }
}

.auto-width::part(content) {
    --width: auto;
}

.auto-width-and-height::part(content) {
    --width: auto;
    --height: auto;
}

.info-popover::part(content) {
    --width: 440px;
}

.question-general-popover::part(content) {
    --width: 500px;
}

.rename-package-modal {
    --width: 400px;
}

.select-package-modal {
    --width: 600px;

    &::part(content) {
        --min-height: 450px !important;
    }
}

.set-quote-headers-modal::part(content) {
    --width: 400px;
    --height: 580px;
    max-height: 580px;
}

.add-notes-modal {
    --width: 600px;
}

.project-details-modal {
    --width: 704px;

    &::part(content) {
        --height: auto !important;
        --max-height: auto !important;
    }
}

.create-promo-card-modal {
    --width: 560px;
    --min-height: 515px;
}

.applied-promo-cards-modal {
    --height: 370px;
}

.package-price-attributes-modal {
    &--2 {
        --width: 500px;
    }
    &--3 {
        --width: 600px;
    }
    &--4 {
        --width: 700px;
    }
    &--5 {
        --width: 800px;
    }
}

.items-list-popover::part(content) {
    min-height: 300px;
    background-color: rgba(255, 255, 255, 0.8);
}

.question-checkbox-popover::part(content) {
    --width: 160px;
}

.question-list-popover::part(content) {
    --width: 200px;
}

.dropdown-list-popover::part(content) {
    --max-height: 300px;
}

.wrap-select-option-text {
    .item.select-interface-option ion-label {
        white-space: normal;
    }
}

.items-list-popover::part(content) {
    overflow: hidden;
}

.multi-select-popover {
    &::part(content) {
        --width: 350px;
    }

    &.wider::part(content) {
        --width: 448px;
    }
}

.selection-popover::part(content) {
    --width: 450px;
}

.dropdown-popover {
    &::part(content) {
        --width: 200px;
    }

    &.appointment-actions::part(content) {
        --width: 230px;
    }

    &.measurement-actions::part(content) {
        --width: 330px;
    }

    &.photo-actions::part(content) {
        --width: 260px;
    }

    &.mobile-photo-actions::part(content) {
        --width: 300px;
    }

    &.financing-options::part(content) {
        --width: 300px;
        --max-height: 260px !important;
    }

    &.configuration-package-name::part(content) {
        --width: 315px;
    }

    &.fab-actions::part(content) {
        --width: 250px;
    }
}

.tooltip-popover {
    &.ion-text-nowrap::part(content) {
        --width: max-content;
    }

    &.ion-padding::part(content) {
        padding: 5px 10px;
    }
}

ion-alert.sc-ion-alert-md-h {
    --max-width: 320px;
}

.toast-message {
    white-space: pre-wrap;
}

.copy-opening-within-quote-modal::part(content) {
    --width: 560px;
    height: calc(100% - 150px) !important;
    max-height: 100% !important;
}

html.plt-mobile {
    ion-app {
        user-select: none;
    }

    &.ios ion-app [contenteditable] {
        user-select: text;
    }
}

.pt-0 {
    padding-top: 0;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-0 {
    margin-bottom: 0;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.p-10 {
    padding: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.p-20 {
    padding: 20px;
}

.pt-20 {
    padding-top: 20px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.px-5 {
    padding: 0 5px;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-ellipsis-2-rows {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-segment {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 37px;
    margin-top: 8px;
    font-weight: 600;
    font-size: 12px;

    & > ion-label {
        display: block;
        flex-grow: 1;
        flex-basis: 0;
        padding: 9px 5px;
        overflow: hidden;
        border: 1px solid #ebebeb;
        color: #9c9c9c;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:not(:last-of-type) {
            border-right-color: transparent;
        }

        &.active-segment {
            border: 1px solid #acacac;
            background-color: #f4f4f4;
            color: #231f20;

            & + ion-label {
                border-left-color: transparent;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

ion-select.quantity-select {
    --padding-start: 5px;
    --padding-end: 35px;
}

span.currency {
    position: relative;
    top: 0.33em;
    font-weight: 500;
    font-size: 65% !important;
    line-height: 100%;
    vertical-align: text-top;
}

.font-bold {
    font-weight: bold;
}

@media (min-width: 1281px) {
    .ion-hide-xxl-up {
        display: none !important;
    }
}
@media (max-width: 1280.98px) {
    .ion-hide-xxl-down {
        display: none !important;
    }
}

.d-none {
    display: none !important;
}

.pr-5 {
    padding-right: 5px;
}

.pl-5 {
    padding-left: 5px;
}

.ml-5 {
    margin-left: 5px;
}

.popover-datepicker {
    --width: 300px;

    .datetime-presentation-date-time {
        min-height: 400px;
    }
}

.popover-package-name::part(content) {
    --width: auto;
    padding: 10px;
    font-weight: 600;
}

.intercom-lightweight-app-launcher,
.intercom-messenger-frame,
.intercom-launcher-frame {
    left: 10px !important;
}

@media (max-height: 769px) {
    .intercom-namespace {
        div:not([class^='intercom-']) {
            opacity: 0 !important;
        }

        [class^='intercom-'] {
            &.intercom-messenger-frame {
                bottom: 130px;
                height: calc(100% - 140px);
            }

            &:not(.intercom-messenger-frame) {
                bottom: 90px;
                left: 10px;
            }
        }
    }
}

.bold-text {
    font-weight: 600;
}

ion-textarea:not([autoGrow='true']) .native-textarea {
    overflow-y: auto;
}

ion-textarea {
    --highlight-color: unset !important;
    --padding-top: 10px !important;
}

ion-modal.modal-default:not(.overlay-hidden) {
    & ~ ion-modal.modal-default:last-of-type {
        &.available-update-modal,
        &.auto-width-and-height {
            --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
            --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
            --height: auto;
        }
    }
}

.line-through {
    text-decoration: line-through;
}

.font-weight-500 {
    font-weight: 500;
}

.animation-rotate {
    animation: rotation 10s linear infinite;
}

.wheel-selector .picker-columns {
    margin-bottom: 30px;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.counter {
    display: inline-block;
    position: absolute;
    min-width: 16px;
    min-height: 16px;
    padding-top: 1px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 11px;
    text-align: center;
}

.hidden {
    visibility: hidden;
}

ion-toast.warning-toast {
    --background: #b42318;
    --width: 650px;
    --color: var(--ion-color-white);
    --ion-color-step-100: var(--ion-color-white);

    .toast-button-cancel {
        color: var(--ion-color-step-100, var(--ion-color-white));
    }
}

.fs-14 {
    font-size: 14px;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 11px 13px 20px;

    .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
    }

    ion-button {
        --padding-start: 0;
        --padding-end: 0;
        --border-radius: 50%;
        --border-width: 0;
        width: 20px;
        height: 20px;
        min-height: 20px;
        margin-top: 0;

        ion-icon[name='close'] {
            align-self: flex-start;
            min-width: 20px;
            height: 20px;
            color: #6d6d6d;
        }
    }
}

.modal-body {
    flex: 1;
    padding: 0 20px;
}

.modal-footer {
    display: flex;
    align-items: center;
}

.tagify__tag {
    --tag-bg: #8a8a8a;
    --tag-hover: #8a8a8a;
    --tag-remove-bg: #8a8a8a;
    --tag-remove-btn-bg--hover: #8a8a8a;
    --tag-text-color: var(--ion-color-white);
    --tag-remove-btn-color: var(--ion-color-white);
    --tag-border-radius: 50px;
    margin-bottom: 0;
}

.tagify__dropdown {
    .tagify__dropdown__wrapper {
        --tagify-dd-color-primary: var(--ion-color-primary);
    }
    .tagify__dropdown__item--active {
        --tagify-dd-color-primary: var(--ion-color-primary);
        --tagify-dd-text-color: var(--ion-color-on-primary);
    }

    border: none;
    border-top: 1px solid var(--ion-color-primary);
}

.select-interface-option ion-radio::part(label) {
    overflow: auto;
    text-overflow: initial;
    white-space: normal;
    margin: 0;
}

.ng-select {
    width: 100%;

    .ng-value-container {
        width: calc(100% - 20px);
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-radius: 5px;
        border-color: #cccccc;
        box-shadow: none;
    }

    &.ng-select-multiple .ng-select-container .ng-value-container {
        .ng-value {
            display: flex;
            flex-direction: row-reverse;
            max-width: calc(100% - 20px);
            background-color: #8a8a8a;
            padding: 5px;
            border-radius: 50px;
            color: var(--ion-color-white);
            align-items: center;

            .ng-value-label {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .ng-value-icon.left {
                border-right: unset;
                border-left: unset;
                color: var(--ion-color-white);
                font-size: 20px;
                height: 20px;
                padding-left: 0;
                margin-top: -5px;

                &:hover {
                    background-color: #8a8a8a;
                    border-radius: 50px;
                }
            }
        }

        .ng-placeholder {
            padding-top: 3px;
        }
    }
}

.ng-dropdown-panel {
    box-shadow: 0 1px 5px #959595;
    border: none;

    .ng-dropdown-panel-items {
        .ng-option,
        .ng-option.ng-option-selected,
        .ng-option.ng-option-selected.ng-option-marked {
            display: flex;
            align-items: center;
            word-break: break-word;
            white-space: normal;

            &:not(.ng-option-disabled) {
                &::before {
                    content: '\2713';
                    color: var(--ion-color-white);
                    display: inline-block;
                    line-height: 1em;
                    text-align: center;
                    margin-right: 8px;
                    border-radius: 2px;
                    transition:
                        color 0.2s,
                        background-color 0.2s;
                }
            }
        }

        .ng-option {
            padding: 4px 10px;

            &.ng-option-marked {
                background-color: var(--ion-color-white);
            }

            &:not(.ng-option-disabled) {
                &::before {
                    width: 13px;
                    height: 13px;
                    border: 2px solid var(--ion-color-primary);
                    background-color: transparent;
                }
            }
        }

        .ng-option.ng-option-selected,
        .ng-option.ng-option-selected.ng-option-marked {
            background-color: unset;

            .ng-option-label {
                font-weight: 400;
            }

            &:not(.ng-option-disabled) {
                &::before {
                    width: 15px;
                    min-width: 15px;
                    height: 15px;
                    border: 1px solid var(--ion-color-primary);
                    background-color: var(--ion-color-primary);
                }
            }
        }
    }
}

//replacing existing styles to cancels any in-progress scrolling when the menu opens
.menu-content-open ion-content {
    --overflow: auto;
}
